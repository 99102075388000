import React from "react"
import { Flex, Box } from "@chakra-ui/core"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import axios from "axios"

import Heading from "../../components/typography/Heading"
import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"
import Body from "../../components/typography/Body"
import Paragraph from "../../components/typography/Paragraph"
import FormTitle from "../../components/ContactForms/common/FormTitle"
import TextField, {
  TextFieldProps,
} from "../../components/FormFields/TextField"
import { Button } from "../../components/Buttons"
import TextAreaField from "../../components/FormFields/TextAreaField"
import { BaseDispatch } from "../../redux/store"
import PageContainer from "../../components/PageContainer"

interface CorporateAndBulkdOrdersPageProps {}

const CorporateAndBulkdOrdersPage: React.FC<CorporateAndBulkdOrdersPageProps> = () => {
  const [isCreatingTicket, setIsCreatingTicket] = React.useState(false)
  const [
    isTicketCreatedSuccessfully,
    setTicketCreatedSuccessfully,
  ] = React.useState<boolean | null>(null)

  const dispatch = useDispatch<BaseDispatch>()

  React.useEffect(() => {
    dispatch.sidebar.setIsOpen(false)
  }, [])

  type FormType = {
    firstName: string
    lastName: string
    name: string
    email: string
    phone: string
    company: string
    companyAddressList1: string
    companyAddressList2: string
    city: string
    country: string
    state: string
    zipcode: string
  }

  const methods = useForm<FormType>()
  const onSubmit = async (formValues: FormType) => {
    try {
      const response = await axios.post(
        `/.netlify/functions/bulkOrders`,
        formValues
      )
      setTicketCreatedSuccessfully(response.data?.success)
    } catch (error) {
      setTicketCreatedSuccessfully(false)
    } finally {
      setIsCreatingTicket(false)
    }
  }

  const companyInfoFields: Array<TextFieldProps> = [
    {
      name: "firstName",
      placeholder: "First Name",
      type: "text",
      isRequired: true,
    },
    {
      name: "lastName",
      placeholder: "Last Name",
      type: "text",
      isRequired: true,
    },
    { name: "email", placeholder: "Email", type: "email", isRequired: true },
    { name: "phone", placeholder: "Phone", type: "phone" },
    { name: "company", placeholder: "Company Name", type: "text" },
    {
      name: "companyAddressLine1",
      placeholder: "Company Address Line 1",
      type: "text",
    },
    {
      name: "companyAddressLine2",
      placeholder: "Company Address Line 2",
      type: "text",
    },
    { name: "city", placeholder: "City", type: "text" },
    { name: "country", placeholder: "Country", type: "text" },
    { name: "state", placeholder: "State / Province", type: "text" },
    { name: "zipcode", placeholder: "Postal / Zip Code", type: "text" },
  ]

  return (
    <PageContainer>
      <Flex justify="center" w="100%" mt={[null, null, null, null, "8.375rem"]}>
        <Heading
          as="h2"
          size="2"
          fontWeight="bold"
          mx={["1rem", null, null, null, "0"]}
        >
          {"Corporate & Bulk Orders"}
        </Heading>
      </Flex>
      <MaxWidthGrid>
        <Body
          fontWeight="bold"
          gridColumn={["1/3", null, null, null, "4 / 12"]}
          mt={["3rem", null, null, null, "7.5625rem"]}
        >
          How to order
        </Body>
        <Paragraph
          gridColumn={["1/3", null, null, null, "4 / 12"]}
          mt="1.25rem"
        >
          Sixthreezero offers special pricing for bulk orders and corporate
          purchasing, whether it be for employee gifts, rental bicycles for
          hotels and resorts, or for your campus-provided transportation.
          <br />
          <br />
          To apply for bulk order and corporate pricing, please fill out the
          form with your contact information, shipping destination, and quantity
          of bikes you are looking to purchase. One of our friendly sales
          representatives will contact you with pricing and availability.
          <br />
          <br />
          Thank you!
        </Paragraph>
        <FormProvider {...methods}>
          <Box
            as="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            gridColumn={["1/3", null, null, null, "4 / 12"]}
          >
            <FormTitle mt={["3rem", null, null, null, "6.25rem"]}>
              Company Information
            </FormTitle>
            <Flex justify="space-between" flexWrap="wrap" w="100%">
              {companyInfoFields.map((props, i) => (
                <TextField
                  key={props.name}
                  mt="1.25rem"
                  ml={[null, null, null, null, i % 2 === 1 ? "1.25rem" : null]}
                  mr={[null, null, null, null, i % 2 === 0 ? "1.25rem" : null]}
                  w={[null, null, null, null, "calc(50% - 1.25rem)"]}
                  {...props}
                  ref={methods.register}
                />
              ))}
            </Flex>
            <FormTitle mt={["4rem", null, null, null, "7.75rem"]}>
              About the Order
            </FormTitle>
            <Paragraph>{`Tell us about your business and the purpose of the bikes (rentals, gifts, campus transportation, schools, etc....)`}</Paragraph>
            <Box>
              <TextAreaField
                name="purpose"
                placeholder="Purpose of bikes"
                h="14.25rem"
                mt={["1rem", null, null, null, "1.25rem"]}
                ref={methods.register}
              />
              <TextField
                name="bikeType"
                placeholder="Type of Bicyle"
                mt={["1rem", null, null, null, "1.25rem"]}
                ref={methods.register}
              />
              <Flex flexDir={["column", null, null, null, "row"]}>
                <TextField
                  name="quantity"
                  placeholder="Quantity of Bikes"
                  type="number"
                  mt={["1rem", null, null, null, "1.25rem"]}
                  isRequired={true}
                  ref={methods.register}
                />
                <TextField
                  name="deliveryDate"
                  placeholder="Date of required delivery"
                  color="dawn"
                  mt={["1rem", null, null, null, "1.25rem"]}
                  ref={methods.register}
                />
              </Flex>
            </Box>
            <FormTitle mt={["3rem", null, null, null, "6.5rem"]}>
              Additional Comments
            </FormTitle>
            <TextAreaField
              name="additionalComments"
              placeholder="Enter any additional comments or customization details."
              h="14.25rem"
              mt="1.375rem"
              ref={methods.register}
            />
            <Flex
              justify="center"
              w="100%"
              mt={["2rem", null, null, null, "4.25rem"]}
              mb={["3rem", null, null, null, "7.875rem"]}
            >
              <Button
                type="submit"
                isDisabled={
                  isCreatingTicket || isTicketCreatedSuccessfully === true
                }
              >
                {isTicketCreatedSuccessfully === null
                  ? "Submit"
                  : isCreatingTicket
                  ? "Sending..."
                  : "Sent!"}
              </Button>
            </Flex>
          </Box>
        </FormProvider>
      </MaxWidthGrid>
    </PageContainer>
  )
}

export default CorporateAndBulkdOrdersPage
